import Carousel           from 'react-bootstrap/Carousel';
import Button             from 'react-bootstrap/Button';

import './MainCarousel.css'

export default function MainCarousel(){

    const blockquoteStyle = {
        borderLeft:"5px solid #F66E5E", 
        paddingLeft:"10px", 
        color:"003049"
    }

    return(
        
    <Carousel data-bs-theme="dark">
        <Carousel.Item>
            <div className="carouselItemContentDiv" >
                <img
                    className="imgDefault"
                    src="images/giphy_backups/test.gif"
                    alt="Data Visualization Gif"
                />
                <blockquote style={blockquoteStyle}>
                    <h3>Data Visualizations with D3.js</h3>
                    <p>are an assortment of different <b>web-based projects</b> using the <b>D3.js</b> library to create custom interactive data visualizations 
                    The objective of these projects was to show the ability to explore potential users the ability to <b>explore multi-faceted data through interactions</b> and visualization techniques without aggregation.
                    </p>
                     {/* <Button variant="outline-primary" disabled="true">
                        See More
                     </Button> */}
                </blockquote>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="carouselItemContentDiv" >
                <img
                    className="imgDefault"
                    src="images/prototype/cardprompt.gif"
                    alt="Sticker Shuffle Card Game Gif"
                />
                <blockquote style={blockquoteStyle}>
                    <h3>Sticker Shuffle</h3>
                    <p>is a <b>card game</b> prototype, exploring mashing spot-the-difference game mechanics with a bluff/deduction match setting using the <b>Unity Engine</b>.
                    </p>
                    <Button href="https://ihmayor.itch.io/sticker-shuffle" variant="outline-primary" >
                        Play Prototype
                     </Button>
                </blockquote>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="carouselItemContentDiv" >
                <img
                    className="imgDefault"
                    src="images/giphy_backups/CatGame.gif"
                    alt="Mobile Game Gif"
                />
                <blockquote style={blockquoteStyle}>
                    <h3>C.A.T (Clouds Awakening Trouble)</h3>
                    <p>is a <b>block stacking strategy mobile game</b> focused on tower building block by block to reach higher heights.  
                    This was a team project alongside a diverse skillset of artists, designers and managers and developed using the <b>Unity Engine</b>. 
                    </p>
                    <Button href="https://ihmayor.itch.io/cat-stacking-game" variant="outline-primary" >
                        Play Game
                     </Button>
                </blockquote>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="carouselItemContentDiv" >
                <img
                    className="imgDefault"
                    src="images/prototype/roan_2.gif"
                    alt="Survival Shotter Game"
                />
                <blockquote style={blockquoteStyle}>
                    <h3>Journey to the Chapel</h3>
                    <p>is a <b>2D shooter/typer game</b> where the character must complete writing her letter while fighting off incoming enemies. 
                    This was a project dedicated to learning how to use the <b>Godot Engine</b>.
                    </p>
                     <Button href="https://ihmayor.itch.io/journey-to-the-chapel" variant="outline-primary" >
                        Play Game
                     </Button>
                </blockquote>
            </div>
        </Carousel.Item>
   </Carousel>
    );
}