
import {Container, Button,Col,Row} from 'react-bootstrap'
import {InView} from 'react-intersection-observer'

import './WallStory.css';


export default function WallStory() {
    return ( 
       <div className="bg-gray">
            <div class="row section-heading wow fadeInUp">
                <InView>
                    {({ inView, ref, entry }) => (
                        <h2 ref={ref} className={'wallTitle ' + (inView ? 'animate__animated animate__fadeInUp' :'' )}>A place uncertain</h2>
                    )}
                </InView>
            </div>
            <p>Let's be clear. No one wanted shivering walls. Cynthia knew this as she watched the client sit down across from her.</p>
            <p>There were three big reasons. First off, they were pricey. This shut down the curious and the reasonable. Who needed a wall that moved and jutted out? Secondly, you could ‘supposedly’ accomplish many of the same things with hide-away furniture. Third, they looked dangerous and the way that the media team swung themselves around in the demo videos did not help. However, ruminating on such, was not going to change the situation either. What they needed was a good setup and good connections. </p>
            <p>“Look, I get that it's your job, but you’re coming off a bit too insistent with multiple calls.”</p>
            <p> She straightened her back more. Her smile tightened.</p>
            <p>
                “Gotta get that foot into doors.”
            </p>
            <p>
                “-and a hand into the walls, so it seems” The client quipped.
            </p>
            <p>
                They flipped through more of the papers set before them. This had been her eighth meeting with another potential distributor this week. Her employers pushed for the connections, no matter how dismissive they were to the technology.
            </p>
            <p>
                “Be honest, how many have bought into this?”
            </p>
            <p>
                “Um, well the gym rooms tend to be quite popular,” she replied instead. Numbers would scare them off. “The walls become an ever-changing challenge, and end customers quite like the extra twist to them.”
            </p>
            <p>
                “Extra twist? Is there more beyond the videos?”
            </p>
            <p>
                She hid her smile. “Would you like to see?”
            </p>
            <p>
                She invited the client off of the facing couches where they were seated and towards another room down the hall. They strolled quietly.  Her fingers traced lightly on the keypad to the room which bubbled back at her then opened the door.
            </p>
            <p>
                A towering 39 feet wall greeted them with a light ripple of its panels. Colors as vivid as a parrot's feathers splashed across the wall as it activated. The client walked up closer and brushed along a square which jittered under hands. They peeked their head around its sides, trying to peer into the mechanics.
            </p>
            <p>
                “Is there a projector sitting behind here?”
            </p>
            <p>
                Cynthia shook her head and grinned, “None. Like the iridescent blue on most butterflies, these panels require no electricity or chemicals to get their color. Smart microscopic structures on the panel's surface adapt to match certain wavelengths to reflect certain colors back to our eyes. A hard clear coating covers that to reduce scratching or liquid damage. This walls’ color will last longer than any chemical paint coat.”
            </p>
            <p>
                Her client hummed with a measured length. After a few more seconds, the wall settled onto a neutral grey-blue color. A few of the panels in their vicinity jutted out and vibrated eagerly. Edges rounded as the client’s hands approached a panel. They looked back at Cynthia before making contact.
            </p>
            <p>
                “Grab it towards you. The harder you grab or push inwards then the more rigid the wall's material will become. It mimics what you give it.”
            </p>
            <p>
                The panel blushed orange upon touch. The client yanked hard then released it. After a few seconds, it settled back into blue. It remained jutted out, even as the client slammed their body against it. The client took that moment to hang their coat on it and rest their papers.
            </p>
            <p>"How does it reset?"</p>
            <p>"Grab around the edges again and push it in," she explained as she pushed the panel an inch inwards.</p>
            <p>
                “And this is how you activate the rock climbing feature”, Cynthia added as she was walked to the other side of the room. She pulled out another set of random panels in a pattern headed upwards. She tapped her newfound structure until they turned green then back to blue. Soon a ripple ran through the structure and a typical rock climbing pattern raised itself outwards. The previous structures remained the same.
            </p>
            <p>
                For the next half hour, Cynthia went through the rest of the basic features of the wall. By walking a few steps back then running to just a hovering nose tip away, she showed off its sensitivity to proximity with its varied intensity of activity. At some point, she welcomed the client to smack their entire body against the wall and an amusing imprint of both color and form revealed itself after a little waiting. They exchanged a laugh.
            </p>
            <p>
                “I saw that you have swarm robotics as an optional part of your offered packages. May I see one of them?”
            </p>
            <p>
                Cynthia nodded as her hand dove into her pocket to unveil a small insect-like bot. It had an overshadowing square panel on top of its back with the same design as the panels on the wall. It fit snugly in the size of her palm. Her finger jabbed at it until the machine started to squirm and climb around inside her hand.
            </p>
            <p>
                “This is a T.P.O swarm bot. Looks like a spider, climbs walls like a spider. They can follow near-invisible tracers alongside these walls and keep close to the location of customers. For as long as the customer keeps their beacon activated on their person, the swarm will move and keep attached to the respective routes on the walls. This provides a cheaper alternative to installing active Toph Walls in every room.”
            </p>
            <p>
                “Are these capable of supporting a human body while they’re climbing walls?”
            </p>
            <p>
                “No. Features requiring heavy enduring support are not available with the T.P.O swarm. However, they have their own equally useful features.”
            </p>
            <p>
                She pulled out a triangular crystal and the tiny robot made a path towards it. She then proceeded to attach it to the adjacent wall. It remained there, though it still followed the direction of the beacon. Cynthia clinked the beacon against the robot and a multitude of other robots crawled out of crevices in the ceiling. Every marked inch of the wall filled with the little machines.
            </p>
            <p>
                “Go ahead” She welcomed the client to step forward. “They respond in the same manner as the walls, just don’t try to climb them.”
            </p>
            <p>
                More hesitance weighed down the client’s hand as they grabbed onto one. The small one stretched as the hand pulled it out like a gooey mozarella slice due to its size. Another hand grabbed a much larger group and did the same. They pushed against the pulled-out panels and remarked on its remarkable similar squishiness. Cynthia had one more trick. She held out the beacon and strolled to another adjacent wall. The swarm followed while keeping form of the structures created by the client.
            </p>
            <p>
                “Won’t those get stuck?” The client asked as they pointed to the stand-out machines.
            </p>
            <p>
                “They can revert to their original form to get in-between doors but they will resume your intended shape once they have stopped at their spot.” She pulled out a tennis ball. “This is a more recent addition, I can instruct these droids to carry this ball in a figure-eight.” She traced out a figure eight onto the panels of a select few, placed the ball among them, then retraced the pattern. The ball was soon dancing around the room in that very shape. She glanced at her client who, at this point, was checking their watch. She cleared her throat and wrapped up the demonstration.
            </p>
            <p>
                More minor questions were then asked before the client thanked her then left. They said that they would consult the rest of their company's stakeholders to decide what to do. Nothing solid was confirmed. Her mind translated that to a 'no' but she maintained a blank face as she led them outside. Once she was certain that they were gone, she sighed and headed back to the demo room to reset it.
            </p>
            <p>
                “What am I <i>missing</i>?” Cynthia asked the empty room. The room walls wiggled. Work hours had already passed. This meeting had been booked outside the typical schedule much to her dismay. She had kept her complaints tight-lipped. It was not like she had proved herself an asset yet.
            </p>
            <p>
                Her butt should be in a bus seat headed home by now. Technology kept improving but her motion sickness did not so she would have to endure an hour and half commute. She sighed as she began to pull random cubes towards her. Though unintentional, a rough staircase unveiled itself with a little perch at the top. She shrugged and climbed up.
            </p>
            <p>
                After she perched herself in cross-legged position, the staircase disappeared. Her eyes stared forward at the only normal wall inside the room. Her mind drifted.
            </p>
            <p>
                A storm of worry brewed within her head. Her head buried itself into her hands. Her new city had greeted her with such a saccharine smile. Here stood fresh meat. She had let ambition, fascination and naïve eagerness push caution away.
            </p>
            <p>
                The marketing team ignored her pleas for less flashy demo videos. Her coworkers might as well be robots programmed to make deals. The developers all worked from home. She never told anyone but she secretely pictured them as gnomes trying to pass off bottom of the barrel magical goods for viable cutting-edge technology. Maybe that’s why they never showed their faces. The walls had popped up in their building overnight after all. No one said anything, so she never asked.
            </p>
            <p>
                Her body began to sway. A swing set flashed in her mind and her gut twisted it as she was reminded of her childhood home. Her frowning parents stood at the door before shaking their heads and heading back inside. Cynthia’s last words to them remained lodged within her throat. Time only taught her how to swallow around them. If only they could see her now! Alone in a room and unable to negotiate a single contract!
            </p>
            <p>
                The swaying continued. She imagined cold waves lapping on a warm sand beach somewhere across the world. Each wave grew large as it approached, and her breath would stop right before the collision, then it would all crash down as quickly as it came, taking away bits of sand, taking away bits of her. A cycle of hope and comfort raged away.
            </p>
            <p>
                Her hands pulled out a resting place for her arms and she leaned as far back as she could. She could hear the rushing water now. Their slow acceleration to power comforted to her. Maybe she was rushing too much to get ahead. Day by day, maybe she will get there. Light beeps directed her towards the adjacent wall where she could have sworn were covered with twinkling stars.
            </p>
            <p>
                Half an hour later, she found herself on the floor as she awoke from her nap. Her brows furrowed. She furiously shook her head and whipped her eyes around to reorientate herself. Her arms stretched out and found an edge of support to pull herself up. She patted the wall. As expected, it shivered in response.
            </p>
            <Container style={{display:"block",textAlign:"center"}} className="functionality bg-blue">
                <h2>Order Now!</h2>
                <Button className="btn-xl" size="lg" href="/Experimental/Wall">GO BACK TO PRODUCT PAGE</Button>
            </Container>
       </div>
    );
}