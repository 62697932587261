import {Card, Button} from 'react-bootstrap';
import './PackageCard.css';

export default function PackageCard({title, price, description, pricingList, select, buttonLink}){
    return(
        <Card className={"PackageCard "}>
            <Card.Header className={select} as="h5">
                {title}
            </Card.Header>
            <Card.Body>
                <Card.Title>{price}</Card.Title>
                <Card.Text>
                {description}
                {pricingList}
                </Card.Text>
                <Button className={"btn-package "+(select)} variant="primary" href={buttonLink} >Tell me more!</Button>
            </Card.Body>
        </Card>

    );
}