import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


import './NavigationBar.css'

const homeLink = 
{
  color: 'white',
  fontWeight:'bold',
  fontSize:'large'
}

const navBackground = 
{
  backgroundColor: '#003049'
}

const yellowLink = 
{
  color: '#FCBF49',
  textAlign:'start'
}

export default function NavigationBar() {
  return (
    <Navbar style={navBackground} expand="lg">
      <Container>
        <Navbar.Brand style={homeLink} disabled href="">Irene Honrade Mayor</Navbar.Brand>
        <Navbar.Toggle className="custom-toggler" style={homeLink} />
        <Navbar.Collapse  id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={yellowLink} href="/">Home</Nav.Link>
            <Nav.Link style={yellowLink} href={'/Irene_Honrade_Mayor_Resume_2024.pdf'}>Resume</Nav.Link>
            {/* <Nav.Link disabled href={process.env.PUBLIC_URL+'Timeline'}>Archived Timeline</Nav.Link> */}
            <NavDropdown className="custom-dropdown-toggle align-self-sm-start" style={yellowLink} title="Projects">
              {/* <OverlayTrigger 
                overlay={
                  <Tooltip id="tooltip-disabled" placement="right">
                    Update underway!
                  </Tooltip>
                }>
                  <span className="d-line-block">
                    <NavDropdown.Item disabled href="" style={{pointerEvents:'none'}}>
                      Recent
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item disabled href="/DataViz">
                      Data Visualization Projects
                    </NavDropdown.Item>
                  </span>
              </OverlayTrigger> */}
              <NavDropdown.Item href="/Games">
                Games
              </NavDropdown.Item>
              <NavDropdown.Item href="/Experimental">
                Experimental
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}