import {Container, Row, Col, Card, Button, Image} from 'react-bootstrap'
import {InView} from 'react-intersection-observer'
import { FaLeaf, FaCog, FaGem } from 'react-icons/fa';
import './Wall.css';
import PackageCard from '../components/PackageCard';


export default function Wall() {
    
    return ( 
       <div className='WallMainContent'>
        <div class="alert alert-info">
            <strong>Note! </strong>This was a standalone website emulating a fake futuristic product (2018) for an 'Interactions of the Future' university course. This is a recreation of that site in react. 
        </div>
        <InView>
        {({ inView, ref, entry }) => (
            <div ref={ref}>
                <header className=" WallHeader">
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1 className={(inView? 'animate__animated animate__fadeInDown' : '')}>
                        Make your home as active as you are with <i>Toph Walls</i>! 
                    </h1>
                    <a className="WallLink" href="/Experimental/WallStory">Contact Us</a>
                    <br></br>
                    <br></br>
                    <br></br>
                </header>
                <Container>
                    <Image style={{padding:"0 300px 0 "}} src ="../OldWebsite/CPSC601Backup/Wall/Full_Compo.png" fluid/>
                </Container>
                <Row className={'text-center '+(inView ? 'animate__animated animate__fadeInDown ' : '')}>
                    <Col xs={12} md={4} >
                        <Row>
                            <h4>Eco-Friendly</h4>
                        </Row>
                        <Row>
                            <FaLeaf size="5em"/>
                        </Row>
                        Recycled material composition 
                        </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <b>Flexible</b>
                        </Row>
                        <Row>
                            <FaCog size="5em"/>
                        </Row>
                        Multi-purpose and customizable for your unique home design
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <b>Luxury</b>
                        </Row>
                        <Row>
                            <FaGem size="5em"/>
                        </Row>
                        Cutting-edge technology for premium environments and experiences
                    </Col>
                </Row>
            </div>
        )}
        </InView>
            <Container style={{display:"block",textAlign:"center"}} className="functionality bg-gray">
                <h2 style={{}}>Toph Walls?</h2>
                <p> What are they? What can they do?</p>
                {/* <Container  className="WallImage">
                    <Image src ="../OldWebsite/CPSC601Backup/Wall/Full_Compo.png" fluid/>
                </Container> */}
            </Container>
            <Container>
                <InView>
                    {({ inView, ref, entry }) => (
                        <div ref={ref}>
                            <Row>
                                <h2>
                                    <br/>
                                    Use it for your gym room, your home office, your kids playroom...
                                </h2>
                                <Col>
                                <p>
                                ... anywhere where you feel an actively moving wall could be of use. Toph walls are programmable linear panels that come in any size or style. They respond to gesture, touch and voice to change color and texture. Need a sturdy rock-climbing wall? This will do it. Need a soft wall to punch out your stress? Got it. Need something to easily change the decor of your room? No problem!
        
                                </p>
                                </Col>
                                <Col className={(inView ? 'animate__animated animate__fadeInRight' : '')}>
                                    <Image src ="../OldWebsite/CPSC601Backup/Wall/Room_Versions.png" fluid/>
                                </Col>
                            </Row>
                            <Row  >
                                <h2>
                                    <br/>
                                    <br/>   
                                    Never fading colors with structural coloration coating
                                </h2>
                                <Col className={(inView ? 'animate__animated animate__fadeInLeft' : '')}>
                                    <Image src ="../OldWebsite/CPSC601Backup/Wall/Scale_Small.png" fluid/>
                                </Col>
                                <Col>
                                    <p>
                                    Like the iridescent blue on the wings of most butterflies, the panels on Toph Walls do not contain a single pigment of color. Miraculous biomimicry nano-technology morphologically changes tiny structures on top of each panel to reflect and absorb certain colors. This process depicts the color that you see on our walls.                        
                                    </p>
                                </Col>
                                <br/>
                                <br/>
                            </Row>
                        </div>
                    )}       
                </InView>
                <InView>
                    {({ inView, ref, entry }) => (
                        <div ref={ref}>
                            <Row >
                                <h2 className={(inView ? 'animate__animated animate__fadeInLeft':'')}>
                                    <br/>
                                    <br/>   
                                    Reactive technology
                                </h2>
                                <Col className={(inView ? 'animate__animated animate__fadeInLeft':'')}>
                                    <p>
                                    ... anywhere where you feel an actively moving wall could be of use. Toph walls are programmable linear panels that come in any size or style. They respond to gesture, touch and voice to change color and texture. Need a sturdy rock-climbing wall? This will do it. Need a soft wall to punch out your stress? Got it. Need something to easily change the decor of your room? No problem!
                                    <br/>
                                    <br/>
                                    </p>
                                </Col>
                                <Col>
                                    <Image className={(inView ? 'animate__animated animate__fadeInRight':'')} src ="../OldWebsite/CPSC601Backup/Wall/Wall_Reactive.png" fluid/>
                                </Col>
                            </Row>
                            <Row>
                                <h2 >
                                    <br/>
                                    <br/>   
                                    Actively morphing shapes
                                </h2>
                                <p >
                                ... anywhere where you feel an actively moving wall could be of use. Toph walls are programmable linear panels that come in any size or style. They respond to gesture, touch and voice to change color and texture. Need a sturdy rock-climbing wall? This will do it. Need a soft wall to punch out your stress? Got it. Need something to easily change the decor of your room? No problem!
                                </p>
                                <iframe src="https://giphy.com/embed/LS3PGnbRUDhKuNVJLQ" width="480" height="330" frameBorder="0" className="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/LS3PGnbRUDhKuNVJLQ"></a></p>
                            </Row>
                        </div>
                    )}
                </InView>
                
                <Row >
                    <h2>
                        <br/>
                        <br/>
                        A spectrum of synthesized material
                    </h2>
                    <Col>
                        <InView>
                            {({ inView, ref, entry }) => (
                                <Image ref={ref} className={inView ? 'animate__animated animate__fadeInLeft' : ''} src ="../OldWebsite/CPSC601Backup/Wall/Wall_Synth_Mat.png" fluid/>
                            )}
                        </InView>
                    </Col>
                    <Col>
                        <p>
                        ... anywhere where you feel an actively moving wall could be of use. Toph walls are programmable linear panels that come in any size or style. They respond to gesture, touch and voice to change color and texture. Need a sturdy rock-climbing wall? This will do it. Need a soft wall to punch out your stress? Got it. Need something to easily change the decor of your room? No problem!
                        </p>
                    </Col>
                    
                </Row>
                <Row>
                    <h2>
                        <br/>
                        <br/>
                        T.P.O Swarms: A mobile version of our walls
                    </h2>
                    <Col>
                        <p>
                            <br/>
                                The <b>T</b>ime <b>P</b>lace <b>O</b>ccasion Swarm is a cheap alternative to bringing Toph Wall capabilities to every corner of your house. 
                                They stretch outwards 5 feet more than Toph Walls! Never worry about falling down the stairs. T.P.O swarm is there!    
                        </p>
                    </Col>
                    <Col>
                        <InView>
                            {({ inView, ref, entry }) => (
                                <Image ref={ref} className={inView ? 'animate__animated animate__fadeInRight' : ''} src ="../OldWebsite/CPSC601Backup/Wall/TPO_SWARM.png" fluid/>
                            )}
                        </InView>
                    </Col>
                </Row>
                <Row>
                    <h2>
                        <br/>
                        <br/>
                        Robust and strong
                    </h2>
                    <p>
                    Don't be too afraid of damaging our Toph Walls. They are the furthest thing from fragile! Our walls are smart and will react to handle most fast oncoming forces. Go ahead! Our walls can handle it!                        </p>
                        <iframe src="https://giphy.com/embed/S73H3Gl7VzsU84ciHw" width="480" height="348" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/S73H3Gl7VzsU84ciHw"></a></p>
                    <br/>
                    <br/>
                </Row>
            </Container>
            <InView>
            {({ inView, ref, entry }) => (
                <Container ref={ref} style={{display:"block",textAlign:"center"}} className="functionality bg-gray">
                    <h2 className={inView? "animate__animated animate__fadeInDown": ''}><b>Reviews</b></h2>
                    <p> Listen to the happy experiences of others!</p>
                    <Row className={inView?'animate__animated animate__fadeIn' : ''}>
                        <Col className="review" xs={12} md={4}>
                            <img src="../OldWebsite/CPSC601Backup/Wall/quote.png"/>
                            <div className="fadeIn">
                                <p>
                                    Worth every cent! No more expensive renovations to change up the texture and look of your walls. 
                                    I love that I can make awnings outside whenever the mood strikes me then painlessly take them down.
                                </p>
                                <p>
                                    -Sarah Lynn
                                </p>
                            </div>
                        </Col>
                        <Col className="review" xs={12} md={4}>
                            <img src="../OldWebsite/CPSC601Backup/Wall/quote.png"/>
                            <div className="fadeIn">
                                <p>
                                I got it to push my work towards me while I remained on the other side of the room. 
                                I can also create space above me to store items temporarily and remind me if I accidentially leave them there as I exit the room
                                </p>
                                <p>
                                    -Patrick Starlinsky
                                </p>
                            </div>
                        </Col>
                        <Col className="review" xs={12} md={4}>
                            <img src="../OldWebsite/CPSC601Backup/Wall/quote.png"/>
                            <div className="fadeIn">
                                <p>
                                Never get bored with your ever-changing gymspace! 
                                The kids love it too! 
                                With easy-to-use panels with mnay safety features, let their imaginations run wild!
                                Get them away from their screens by letting them climb around their own creations!
                                </p>
                                <p>
                                    -Taylor McHominid
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
             )}
            </InView>
            
            <Container className="functionality bg-yellow">
                <InView>
                {({ inView, ref, entry }) => (
                    <h5 ref={ref} className={inView ? 'animate__animated animate__fadeIn' : ''}>
                        <b>
                            Plain spaces become textured, interesting, and alive. A world of imaginative potential lies underneath Toph Wall's sleek simple design!    
                        </b>
                    </h5>
                )}
                </InView>
            </Container>
            <img className="BlogImage cards-row img-row" src="../OldWebsite/CPSC601Backup/Wall/Wall_Real_Full.png"/>
            <Container className="functionality bg-yellow">
                <InView>
                {({ inView, ref, entry }) => (
                    <h5 ref={ref} className={inView ? 'animate__animated animate__fadeIn' : ''}>
                        <b>
                            Even the T.P.O swarm is aware of room aesthetics! Their default resting state melds well into any room!
                        </b>
                    </h5>
                )}
                </InView>
            </Container>
            <img className="BlogImage cards-row img-row" src="../OldWebsite/CPSC601Backup/Wall/Wall_Real_Swarm.png"/>
            <Container style={{display:"block",textAlign:"center"}} className="functionality bg-gray">
                <h2>Packages we offer</h2>
            </Container>

            <InView>
            {({ inView, ref, entry }) => (
                <Container ref={ref} className={(inView ? 'animate__animated animate__fadeIn' : '')}>
                    <Row>
                        <Col xs={12} md={4} className="PackageRow">
                            <PackageCard 
                                title="TophWall"
                                price="$5999/wall"
                                description="Deck out that one special room <i>and</i> stay within your budget! Not only is it customizable but it can support up to 200lbs around a single panel's edges."
                                pricingList=
                                {
                                    <ul className="PricingList" >
                                        <li>
                                            <p>
                                                <strong>Up to</strong> 38 feet high
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Strength limit</strong> 200 lbs
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Panels</strong> customizable sizes
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Free 24/7 Support Services</strong> 4 years
                                            </p>
                                        </li>
                                    </ul>
                                }
                                buttonLink="/Experimental/WallStory"
                            />
                        </Col>
                        <Col xs={12} md={4} className="PackageRow">
                            <PackageCard 
                                title="T.P.O Swarm"
                                price="$14999/swarm"
                                description="Create an active wall anywhere around your house! 
                                    Each swarm contains 500 fully-equipped intelligent small bots capable of carrying 4-times their weight.."
                                pricingList=
                                {
                                    <ul className="PricingList" >
                                        <li>
                                            <p>
                                                <strong>Up to</strong> 12 feet high
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Strength limit</strong>  10lbs
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>A.I</strong> robust problem solvers
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Free 24/7 Support Services</strong> 6 years
                                            </p>
                                        </li>
                                    </ul>
                                }
                                buttonLink="/Experimental/WallStory"
                            />
                        </Col>
                        <Col xs={12} md={4} className="PackageRow">
                            <PackageCard 
                                    title="T.P.O Swarm + Toph Wall"
                                    price="$16999 /pair"
                                    description="Want both the mobile aptitude of the swarms and the sturdiness of the walls? 
                                    You save over 100$ each when you buy in pairs. 
                                    Don't need that large of a swarm? Let us know and we can make a special deal for you."
                                    pricingList=
                                    {
                                        <ul className="PricingList" >
                                            <li>
                                                <p>
                                                    <strong>Up to</strong> 50 feet high
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <strong>All previous benefits of</strong>  swarm + walls
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <strong>Symbiotic compatability</strong> between Toph Walls and T.P.O Swarms
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <strong>Free 24/7 Support Services</strong> 10 years
                                                </p>
                                            </li>
                                        </ul>
                                    }
                                    select="activePackage"
                                    buttonLink="/Experimental/WallStory"
                                />
                        </Col>
                    </Row>
                </Container>
            )}
            </InView>
            <Container style={{display:"block",textAlign:"center"}} className="functionality bg-blue">
                <h2>Let's go and make your home alive today!</h2>
                <Button className="btn-xl" size="lg" href="/Experimental/WallStory">GET A QUOTE NOW</Button>
            </Container>
            
            <Row>
                <Col xs={12} md={5}>
                </Col>
                <Col xs={12} md={5}>                
                </Col>
            </Row>
        </div>
    );
}