import ParagraphPanel from "../components/ParagraphPanel";

export default function DataVisualization() {
    return (
       <div>
            <ParagraphPanel
                titlePanel="Data Visualization"
                textPanel=""
                borderVariant="default"
            />
       </div>
    );
}