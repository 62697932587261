import ParagraphPanel from '../components/ParagraphPanel';
import './ArchivedTimeline.css'
import React from 'react';

export default function ArchivedTimeline(){
    return(
        <div>
            <ParagraphPanel
                titlePanel="Archive"
                textPanel=""
                borderVariant="default"
            />
        </div>
    );
}