import {Container, Row, Col, Button, Image} from 'react-bootstrap'
import './Prototype.css';
import ParagraphPanel from "../components/ParagraphPanel";

export default function Prototype() {
    return (
       <div className="parallaxproto">
            <ParagraphPanel
                titlePanel="Rough Drafts"
                textPanel="Ideas fleshed out just enough to test them out"
                borderVariant="dark"
            />
            <div className="PrototypeContent">
                <Row>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/runaround.gif"/>
                    </Col>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/throwermoose.gif"/>
                    </Col>
                        <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/cardproto.gif"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/cinemachinetest.gif"/>
                    </Col>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/matchstyxproto.gif"/>
                    </Col>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/roan.gif"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/roachproto.gif"/>
                    </Col>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/typingproto.gif"/>
                    </Col>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/animalproto.gif"/>
                    </Col>
               </Row>
               <Row>
                    <Col xs={12} md={4}>
                        <Image thumbnail roundedCircle src="../images/prototype/audibleproto.gif"/>
                    </Col>
               </Row>
                <Container>
                </Container>
                <Container>
                </Container>
                <br/>
                <br/>

                <br/>

                <br/>
                <br/>
                <br/>

                <br/>

                <br/>

            </div>
       </div>
    );
}