import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Experimental from "./pages/Experimental";
import Wall from "./pages/Wall";
import WallStory from "./pages/WallStory";
import Games from "./pages/Games";
import DataVisualization from "./pages/DataVisualization";
import ArchivedTimeline from "./pages/ArchivedTimeline";
import Prototype from "./pages/Prototype";
import React  from 'react';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="DataViz" element={<DataVisualization />} />
          <Route path="Timeline" element={<ArchivedTimeline />} />
          <Route path="Games" element={<Games />} />
          <Route path="Experimental" element={<Experimental />} />
          <Route path="Experimental/Prototype" element={<Prototype />} />
          <Route path="Experimental/Wall" element={<Wall />} />
          <Route path="Experimental/WallStory" element={<WallStory />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;