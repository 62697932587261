import {Container, Row, Col, Button} from 'react-bootstrap'

import ParagraphPanel from "../components/ParagraphPanel";

export default function Experimental() {
    return (
       <div>
            <ParagraphPanel
                titlePanel="Experimental"
                textPanel="Miscellaneous projects that explore creative out-of-the-box concepts"
                borderVariant="default"
            />
            <Container>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/protologo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                    <p> A collection of projects that aim to test out a mechanic or an interaction. Most come from sudden inspiration to see if two features seen from games could be 
                        mashed together to create something new. While this site contains a lot of prototypes these are the roughest versions of these ideas.
                    </p>
                    <Button variant="dark" size="lg" href="Experimental/Prototype">Check them out!</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/dndlogo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p> A <b></b>3D simulation of an AR phone set-up<b></b> for displaying a comic book style narrative using <b>Unity Engine</b>. 
                        This was an attempt at creating an innovative interactive storytelling experience while learning a new tool "Vuforia". 
                        Unfortunately due to COVID causing <b>limited access to resources</b>, as well as further external pressures with heavier credited work, 
                        a simulation had to be used instead. Nevertheless, the main goal was to communicate a narrative clearly. 
                        It focuses on <b>utilizing visualization techniques</b> learned in the course to convey tensions and maintain user engagement. </p>
                        <Button variant="dark" size="lg" href="../OldWebsite/projectdnd.html" >Take a look!</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/walllogo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                    <p> A <b>conceptual</b> future artifact and short story envisioning a potential near-future technology. They are based off existing <b>Tangible User Interface technology</b> that could mimic the force feedback of different surfaces based on programmed motors and panels. It tries to imagine how far that concept of multiple little pieces forming a single surface could go. At the same time, it explores the varied amount of interactions that could possible. </p>
                    <Button variant="dark" size="lg" href="Experimental/Wall">See the project!</Button>
                    </Col>
                </Row>
            </Container>
       </div>
    );
}