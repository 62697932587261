import Row       from 'react-bootstrap/Row';
import Col       from 'react-bootstrap/Col';
import './ParagraphPanel.css';

const rowPadding = {
    padding:"2%"
}

const paragraphStyle = {
    textAlign:"center"
}

export default function ParagraphPanel({titlePanel, textPanel, borderVariant}) {
    

    return (
        <div style={rowPadding}>
            <Row>
                <Col>
                    <div className={"borderPanel " + borderVariant}>
                        <div style={paragraphStyle}>
                            <h1 >
                                {titlePanel}
                            </h1>
                            {textPanel}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}