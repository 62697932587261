import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faItchIo} from '@fortawesome/free-brands-svg-icons';
import './Footer.css'

const linkFooterStyle = {
    padding: '10px',
    textAlign:'center'
}


export default function Footer(){
    return(
        <footer style={{backgroundColor:"#003049"}}>
            <div className="shineAnimatedFooter"/>
            <div className="baseAnimatedFooter"/>
            <div className="links" style={linkFooterStyle}>
                <p style={{color:"white"}}>
                    Connect with me on LinkedIn or check out my work on GitHub/Itch.io
                </p>
                <a className='linkedIn' href="https://www.linkedin.com/in/irene-mayor/">
                    <FontAwesomeIcon className="iconShrink" icon={faLinkedin} color="#FCBF49"  size="3x"/>
                </a>
                <a className='itch' href="https://ihmayor.itch.io">
                    <FontAwesomeIcon className="iconShrink" icon={faItchIo} color="#FCBF49"  size="3x"/>
                </a>
                <a className='git' href="https://github.com/Ihmayor">
                    <FontAwesomeIcon className="iconShrink" icon={faGithub} color="#FCBF49" size="3x"/>
                </a>
            </div>
        </footer>
    );
}